import { Outlet } from "react-router-dom";
import Footer from "./components/footer/Footer";
import NavBar from "./components/navbar/Navbar";
import Banner from "./components/banner/Banner";
import axios from "axios";
import { FETCH } from "./Fetch";
import { useState, useEffect } from "react";

const PublicRoutes = () => {
  const [displayBanner, setDisplayBanner] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/banner/get-all`);
        if (res.data[0]) {
          setDisplayBanner(res.data[0].display);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [displayBanner]);

  return (
    <>
      <NavBar />
      {displayBanner && <Banner />}
      <Outlet />
      <Footer />
    </>
  );
};

export default PublicRoutes;
