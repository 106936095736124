import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FETCH } from "../../Fetch";
import "./bannerForm.scss";
import Cookies from "js-cookie";

const BannerForm = () => {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [banner, setBanner] = useState({
    display: true,
    content: "",
  });

  const submitBanner = async (e) => {
    e.preventDefault();
    try {
      const createdBanner = await axios.post(`${FETCH}/banner/add`, banner, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (createdBanner) {
        console.log("Banner created");
        navigate("/properties");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bannerFormMain page">
      <Link to="/properties">
        <button className="btn">Back</button>
      </Link>
      <h1>Create banner</h1>
      <form>
        <div className="formGroup">
          <label>Content : {banner.content.length}/100</label>
          <input
            type="text"
            value={banner.content}
            onChange={(e) => {
              setBanner({ ...banner, content: e.target.value });
            }}
          />
        </div>
      </form>
      <button className="btn" onClick={(e) => submitBanner(e)}>
        Create Banner
      </button>
    </div>
  );
};

export default BannerForm;
