import "./navbar.scss";
import Burger from "./Burger";
import Logo from "../../assets/images/logo1-gold.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="navbar">
      <Link to="/">
        {" "}
        <img src={Logo} alt="falcon properties" />
      </Link>
      <Burger />
    </div>
  );
};

export default NavBar;
