import "./footer.scss";
import Logo from "../../assets/images/logo1-gold.png";

const Footer = () => {
  return (
    <div className="footerMain">
      <div className="firstRow">
        <div className="left">
          <img src={Logo} alt="falcon properties" />
        </div>
        <div className="right">
          <div>
            <p>14707 Old Hanover Road, Boring Md. 21020</p>
            <p>falconmdpa@aol.com</p>
          </div>
          <div>
            <p>Office (410) 429-4941</p>
            <p>Cell (410) 227-4649</p>
          </div>
        </div>
      </div>
      <div className="secondRow">
        <p>
          Website made with ❤️ by{" "}
          <a href="https://boring.tech/" target="blank" className="hyperlink">
            Boring Technologies
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
