import { Link, useNavigate } from "react-router-dom";
import "./popnav.scss";
import { LockOutlined } from "@ant-design/icons";

const PopNav = ({ open, setOpen }) => {
  const navigate = useNavigate();
  
  const handleNavigateAndScroll = (event) => {
    event.preventDefault();
    setOpen(false);
    navigate("/");
    // Delay scrolling to ensure the page has navigated
    setTimeout(() => {
      const listSection = document.getElementById("list");
      if (listSection) {
        listSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <ul open={open} className={`popnavUl ${open ? "open" : "close"}`}>
      <li>
        <Link to="/" className="navlinkStyle" onClick={handleNavigateAndScroll}>
          Properties
        </Link>
      </li>
      <li>
        <Link
          to="/contact"
          className="navlinkStyle"
          onClick={() => setOpen(false)}
        >
          Contact
        </Link>
      </li>
      <li>
        <Link
          to="/login"
          className="navlinkStyle"
          onClick={() => setOpen(false)}
        >
          <LockOutlined />
        </Link>
      </li>
    </ul>
  );
};

export default PopNav;
