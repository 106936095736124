export const pricedot = (price) => {
  price = price + "";

  if (price.length > 3) {
    price = price.split("").reverse().join("");

    for (let i = 1; i < price.length; i++) {
      if (i + 2 < price.length) {
        i = i + 2;
        price = [price.slice(0, i) + "." + price.slice(i++)].join("");
      }
    }
    price = price.split("").reverse().join("");
  }
  return price;
};
