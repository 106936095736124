import "./App.scss";
import HomePage from "./components/home/Home";
import ContactPage from "./components/contact/Contact";
import ListPage from "./components/houseAndList/List";
import HousePage from "./components/houseAndList/House";
import Login from "./components/login/Login";
import PropertyList from "./components/propertyList-admin/PropertyList";
import ModifyProperty from "./components/adminForms/ModifyProperty";
import CreateProperty from "./components/adminForms/createProperty";
import BannerForm from "./components/adminForms/BannerForm";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/property/:id" element={<HousePage />} />
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/properties" element={<PropertyList />} />
          <Route path="/modify/:id" element={<ModifyProperty />} />
          <Route path="/create" element={<CreateProperty />} />
          <Route path="/banner" element={<BannerForm />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
