import "./banner.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { FETCH } from "../../Fetch";

const Banner = () => {
  const [content, setContent] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/banner/get-all`);
        if (res.data[0] && !loaded) {
          setContent(res.data[0].content);
          setLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [content, loaded]);

  return (
    <div className="bannerMain">
      <h2>{content}</h2>
    </div>
  );
};

export default Banner;
