import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import "./house.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { pricedot } from "../hooks";
import axios from "axios";
import { FETCH } from "../../Fetch";

const HousePage = () => {
  const { id } = useParams();

  const [house, setHouse] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const getHouse = await axios.get(`${FETCH}/property/property/${id}`);
        console.log(getHouse.data);
        if (
          getHouse &&
          (JSON.stringify(house) !== JSON.stringify(getHouse.data) || !loaded)
        ) {
          setHouse(getHouse.data);
          setLoaded(true);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [house, loaded, id]);

  const navigate = useNavigate();

  const handleNavigateAndScroll = (event) => {
    event.preventDefault();
    navigate("/");
    // Delay scrolling to ensure the page has navigated
    setTimeout(() => {
      const listSection = document.getElementById("list");
      if (listSection) {
        listSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div className="housemain paddingtop">
      <div className="buttonTop">
        <Link to="/" onClick={handleNavigateAndScroll}>
          <button className="btn">Back</button>
        </Link>
      </div>

      <h1>{house.title}</h1>
      <div className="container">
        {house.images && (
          <Carousel>
            {house.images.map((image) => (
              <div>
                <img src={image} alt="property" key={house._id} />
              </div>
            ))}
          </Carousel>
        )}
        <div className="secondcolumn">
          {house.status === "active" && (
            <>
              <p>
                <strong>{house.forRent ? "FOR RENT" : "FOR SALE"}</strong>
              </p>
              {house.displayPrice && house.status !== "sold" && (
                <p>$ {pricedot(house.price)}</p>
              )}
            </>
          )}

          {house.displayAddress && house.status === "active" && (
            <p>{house.address}</p>
          )}
          <p>
            {house.bedroom > 0 && `${house.bedroom}  Beds / `}{" "}
            {house.bathroom > 0 && `${house.bathroom} Baths /`}
            {house.acres > 0 && `${house.acres} Acres`}
          </p>
          {house.displayDescription && <p>{house.description}</p>}
          <Link to="/contact">
            <button className="btn">Contact us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HousePage;
