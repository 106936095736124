import { useEffect } from "react";
import "./contact.scss";
import postOffice from "../../assets/images/postoffice.jpeg";

const ContactPage = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div className="contactMain paddingtop">
      <h1>Contact</h1>
      <div className="secondContainer">
        <div className="image">
          <img src={postOffice} alt="Post office" />
        </div>
        <div className="content">
          <p>falconmdpa@aol.com</p>
          <p>14707 Old Hanover Road, Boring Md. 21020</p>
          <p>Office (410) 429-4941</p>
          <p>Cell (410) 227-4649</p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
