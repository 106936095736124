import { useState } from 'react';
import PopNav from './Popnav';
import './burger.scss';

const Burger = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div onClick={() => setOpen(!open)} className={`burgerMainDiv ${open ? 'open' : 'close'}`}>
                <div />
                <div />
                <div />
            </div>
            <PopNav open={open} setOpen={setOpen}/>
        </>
    )
}

export default Burger;