import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./list.scss";
import axios from "axios";
import { FETCH } from "../../Fetch";
import housePlaceholder from "../../assets/images/house-placeholder.jpg";
import { pricedot } from "../hooks";

const ListPage = () => {
  const [houses, setHouses] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [sortBy, setSortBy] = useState("status");
  const [order, setOrder] = useState("asc");
  const [searchAddress, setSearchAddress] = useState("");
  const imgRefs = useRef([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      imgRefs.current.forEach((imgRef) => {
        if (imgRef) {
          const rect = imgRef.parentElement.getBoundingClientRect();
          const offset = (window.innerHeight - rect.top) * 0.15; // Adjust multiplier for speed
          imgRef.style.transform = `translate(-50%, calc(-50% + ${offset}px))`;
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const getHouses = await axios.get(`${FETCH}/property/properties`, {
          params: { sortBy, order, address: searchAddress },
        });

        if (
          getHouses &&
          (JSON.stringify(getHouses.data) !== JSON.stringify(houses) || !loaded)
        ) {
          setHouses(getHouses.data);
          setLoaded(true);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [houses, loaded, sortBy, order, searchAddress]);

  const displayLabel = (rental, category) => {
    if (rental) {
      if (category !== "residential") {
        return "FOR LEASE";
      } else {
        return "FOR RENT";
      }
    } else {
      return "FOR SALE";
    }
  };

  return (
    <div className="listMain paddingtop" id="list">
      <div className="content">
        {houses.map((house, index) => (
          <Link to={`/property/${house._id}`} key={house._id}>
            <div className="card">
              <span className="img-container">
                <img
                  ref={(el) => (imgRefs.current[index] = el)}
                  src={house.images[0] ? house.images[0] : housePlaceholder}
                  alt=""
                  className={
                    house.status === "sold"
                      ? "sold"
                      : house.status === "undercontract"
                      ? "undercontract"
                      : ""
                  }
                />
                {house.status === "sold" && <h2 className="sold-text">SOLD</h2>}
                {house.status === "undercontract" && (
                  <h2 className="undercontract-text">UNDER CONTRACT</h2>
                )}
              </span>
              <div className="content">
                <div className="title">
                  <span className="blur"></span>
                  <h2>
                    {house.title}, <i>{house.category}</i>
                  </h2>
                  {house.status === "active" && (
                    <h3>
                      {displayLabel(house.forRent, house.category)}
                      {house.displayPrice && " $" + pricedot(house.price)}
                    </h3>
                  )}
                </div>
                <div className="infos">
                  <span className="bg-opac"></span>
                  <p>
                    {house.bedroom > 0 && `${house.bedroom}  Beds / `}{" "}
                    {house.bathroom > 0 && `${house.bathroom} Baths /`}{" "}
                    {house.acres > 0 && `${house.acres} Acres`}
                  </p>
                  {house.displayDescription && (
                    <p>{house.description.substr(0, 120)}...</p>
                  )}
                  <Link to={`/property/${house._id}`}>
                    <button className="btn">See more</button>
                  </Link>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ListPage;
