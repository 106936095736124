import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  if (Cookies.get("token")) {
    const token = Cookies.get("token");

    return token.length && token !== undefined ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoutes;
