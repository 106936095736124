import "./propertyList.scss";
import { FETCH } from "../../Fetch";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteProperty from "../popupDelete/DeleteProperty";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { pricedot } from "../hooks";
import Cookies from "js-cookie";

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [id, setId] = useState(undefined);
  const [banner, setBanner] = useState("");
  const [deleteMsg, setDeleteMsg] = useState("");
  const [sortBy, setSortBy] = useState("creation_date");
  const [order, setOrder] = useState("desc");
  const [searchAddress, setSearchAddress] = useState("");

  const navigate = useNavigate();
  const token = Cookies.get("token");

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/property/properties`, {
          params: { sortBy, order, address: searchAddress },
        });
        if (
          res.data &&
          (JSON.stringify(res.data) !== JSON.stringify(properties) || !loaded)
        ) {
          setProperties(res.data);
          setLoaded(true);
        }
      } catch (error) {
        console.error("error to get properties" + error);
      }
    })();
  }, [properties, displayPopup, loaded, sortBy, order, searchAddress]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/banner/get-all`);
        if (res.data[0]) {
          setBanner(res.data[0]._id);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [banner]);

  const logout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  const deleteBanner = async () => {
    try {
      const deleted = axios.delete(`${FETCH}/banner/delete/${banner}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (deleted) {
        setDeleteMsg("Banner deleted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="propertyMain">
      {token ? (
        <>
          <div className="top">
            <button className="btn" onClick={() => logout()}>
              Logout
            </button>
            {!banner.length ? (
              <Link to="/banner">
                <button className="btn">Create Banner</button>
              </Link>
            ) : (
              <button className="btn" onClick={() => deleteBanner()}>
                Delete Banner
              </button>
            )}
            <Link to="/create">
              <button className="btn">Create Property</button>
            </Link>
          </div>

          <h1>Properties</h1>

          {deleteMsg && <p>{deleteMsg}</p>}
          <input
            className="searchbar"
            type="text"
            placeholder="Search by address"
            value={searchAddress}
            onChange={(e) => {
              setSearchAddress(e.target.value);
              setLoaded(false);
            }}
          />
          <p>Sort by : </p>
          <select
            onChange={(e) => {
              setSortBy(e.target.value);
              setLoaded(false);
            }}
            value={sortBy}
          >
            <option value="status">Status</option>
            <option value="category">Category</option>
            <option value="last_modified">Last Modified</option>
            <option value="creation_date">Creation Date</option>
          </select>

          <select
            onChange={(e) => {
              setOrder(e.target.value);
              setLoaded(false);
            }}
            value={order}
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
          <div className="content">
            {properties.length &&
              properties.map((house) => (
                <div className="card" key={house._id}>
                  <div className="text-content">
                    <p>
                      <b>{house.address}</b>
                    </p>
                    <p>{house.category}</p>
                    <p>${pricedot(house.price)}</p>
                    <p>{house.status}</p>
                  </div>
                  <div className="buttons">
                    <Link to={`/modify/${house._id}`}>
                      <button className="btn">
                        <EditOutlined /> Edit
                      </button>
                    </Link>
                    <button
                      className="btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisplayPopup(true);
                        setId(house._id);
                        console.log(house._id);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </button>
                  </div>
                  {displayPopup && (
                    <DeleteProperty id={id} setDisplayPopup={setDisplayPopup} />
                  )}
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <h1>Unauthorized</h1>
          <Link to="/login">
            <button className="btn">Back</button>
          </Link>
        </>
      )}
    </div>
  );
};

export default PropertyList;
