import { useEffect } from "react";
import "./home.scss";
import bgvideo from "../../assets/bgvideo.mp4";
import Logo from "../../assets/images/logo1-gold.png";
import ListPage from "../houseAndList/List";
import { ArrowDownOutlined } from "@ant-design/icons";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="homeMain">
        <video
          autoPlay
          loop
          muted="muted"
          className="video"
          controlsList="nodownload noremoteplayback"
        >
          <source src={bgvideo} type="video/mp4" />
        </video>
        <div className="content">
          <span className="blur"></span>
          <img src={Logo} alt="falcon properties" />
          <p>
            Falcon Properties is a Boutique Brokerage Firm W/ Almost 40 Years of
            Residential, Commercial, Multi Family, Land, Farms, Development, New
            Construction Waterfront, Leasing and all facets of Real Estate.
          </p>
          <a href="#list">
            <button className="btn">
              <ArrowDownOutlined  style={{fontSize:"40px"}}/>
            </button>
          </a>
        </div>
        <span className="transition"></span>
      </div>
      <ListPage />
    </>
  );
};

export default HomePage;
